import MarkerIcon from '@/assets/img/ico_map_marker@2x.png';
import LocationObj1 from '@/assets/img/tmp/location_obj1@2x.png';
import LocationObj2 from '@/assets/img/tmp/location_obj2@2x.png';
import LocationObj3 from '@/assets/img/tmp/location_obj3@2x.png';
import Container from '@/components/Container';
import ProcessList from '@/components/ProcessList';
import Section from '@/components/Section';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React from 'react';
import NaverMap, { Marker } from 'react-naver-map';
import styled from 'styled-components';

const location1 = [
  {
    id: 1,
    title: `주소`,
    img: LocationObj1,
    desc: [
      {
        dec: `서울시 마포구 서강로 60(창전동) <br />(Changjeon-dong)60 Seogang-ro Mapo-gu Seoul`,
      },
    ],
  },
  {
    id: 2,
    title: `대표번호`,
    img: LocationObj2,
    desc: [
      {
        dec: `02-737-1004 (평일 오전 9시 ~ 오후 6시)`,
      },
    ],
  },
  {
    id: 3,
    title: `팩스`,
    img: LocationObj3,
    desc: [
      {
        dec: `02-724-8555`,
      },
    ],
  },
];
const location2 = [
  {
    id: 1,
    title: `주소`,
    img: LocationObj1,
    desc: [
      {
        dec: `부산광역시 연제구 법원남로 16, 협성법조빌딩 503호<br />Hyeopseong Beopjo Town Bldg, Beopwonnamro yonje-gu Busan (Busan 47511 Korea)`,
      },
    ],
  },
  {
    id: 2,
    title: `대표번호`,
    img: LocationObj2,
    desc: [
      {
        dec: `051-501-0087 (평일 오전 9시 ~ 오후 6시)`,
      },
    ],
  },
  {
    id: 3,
    title: `팩스`,
    img: LocationObj3,
    desc: [
      {
        dec: `051-501-0095`,
      },
    ],
  },
];

const MapApi = styled.div`
  border: solid 1px #e3e7ea;
  padding-top: 68.38%;
`;

const LocationFlex = styled.dl`
  margin: 0 -32px;
  display: flex;
  margin-bottom: 128px;

  &:last-child {
    margin-bottom: 0;
  }

  & > dt {
    width: 54.3%;
    padding: 0 32px;
  }
  & > dd {
    width: 45.7%;
    padding: 0 32px;
  }

  .process-ol {
    margin: 0 !important;
    margin-top: 32px !important;

    .list-item {
      padding: 0;
      width: 100%;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }

      dt {
        width: 56px !important;

        .img-wrap {
          width: 100%;
        }
      }
      dd {
        padding: 0 !important;
        padding-left: 24px !important;
      }
      h3 {
        margin-bottom: 0;
        strong {
          font-size: 16px !important;
          line-height: 2;
        }
      }
      .dec-ul {
        margin-top: 0 !important;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    flex-wrap: wrap;
    margin-bottom: 96px;
    & > dt,
    & > dd {
      width: 100%;
    }
    & > dd {
      margin-top: 48px;

      .process-ol {
        margin-top: 48px !important;

        .list-item {
          margin-bottom: 24px;

          dd {
            padding-left: 20px !important;
          }
        }
      }
    }
  }
`;
const SectionTop = styled(Section)`
  padding-top: 96px;
  ${breakpoint(`tablet`)} {
    padding-top: 48px;
  }
`;

const Location: React.FC<PageProps> = ({ location }) => (
  <LayoutWithTitle location={location} title="오시는 길">
    <SectionTop>
      <Container>
        <LocationFlex>
          <dt>
            <NaverMap
              clientId="i817hf4m5u"
              ncp
              style={{ width: `100%`, height: `40vh` }}
              initialPosition={{ lat: 37.5485034, lng: 126.9320932 }}
              initialZoom={16}
            >
              <Marker
                lat={37.5485034}
                lng={126.9320932}
                icon={{
                  url: MarkerIcon,
                  size: { width: 32, height: 32 },
                  scaledSize: { width: 32, height: 32 },
                  anchor: { x: 12, y: 32 },
                }}
              />
            </NaverMap>
          </dt>
          <dd>
            <Tit size="s2">한국위원회</Tit>
            <ProcessList itemData={location1} type="type-2" />
          </dd>
        </LocationFlex>
        <LocationFlex>
          <dt>
            <NaverMap
              clientId="i817hf4m5u"
              ncp
              style={{ width: `100%`, height: `40vh` }}
              initialPosition={{ lat: 35.1909058, lng: 129.0746507 }}
              initialZoom={16}
            >
              <Marker
                lat={35.1909058}
                lng={129.0746507}
                icon={{
                  url: MarkerIcon,
                  size: { width: 32, height: 32 },
                  scaledSize: { width: 32, height: 32 },
                  anchor: { x: 12, y: 32 },
                }}
              />
            </NaverMap>
          </dt>
          <dd>
            <Tit size="s2">부산사무소</Tit>
            <ProcessList itemData={location2} type="type-2" />
          </dd>
        </LocationFlex>
      </Container>
    </SectionTop>
  </LayoutWithTitle>
);
export default Location;
